import { Taggable } from '../core/core';
import { PersonMatcher } from '../person/person-matcher';

export interface WorkForm extends Taggable {
    id?: { id?: string; version?: string; };
    facilityIds?: string[];
    name: string;
    enabled: boolean;
    status: WorkFormStatus;
    questions: WorkFormQuestion[];
    currentVersion?: number;
    versions?: WorkFormVersion[];
    responseCount?: number;
    whoCanSubmit?: PersonMatcher;
}

export interface SyncWorkForm extends Omit<WorkForm, 'id'> {
    id: string;
    version?: string;
}

export interface WorkFormInput extends Taggable {
    id?: string;
    facilityIds?: string[];
    name: string;
    enabled: boolean;
    status: WorkFormStatus;
    questions: WorkFormQuestionInput[];
    currentVersion: number;
    whoCanSubmit?: PersonMatcher;
}

export interface WorkFormSubmission extends Taggable {
    id?: string;
    form?: {id?: string; version?: string; name?: string;};
    completed: CompletedDetails;
    eventId?: string;
    itemId?: string;
    permitNumber?: string;
    entryId?: string;
}

export interface CompletedDetails {
    personId?: string;
    date?: Date;
    answers: WorkFormAnswer[];
}

export interface WorkFormAnswer {
    qId: string;
    question?: string;
    answer: any;
    answerObject?: {
        ids: string[];
    };
    answerSimple?: any;
}

export interface WorkFormSubmissions extends Taggable {
    totalSubmissions: number;
    submissions: WorkFormSubmission[];
}

export interface WorkFormQuestionAnswer {
    question: WorkFormQuestion;
    answer?: WorkFormAnswer;
    isValid?: boolean;
    errorMessages?: string[];
}

export interface WorkFormCreateRequest extends Taggable {
    name: string;
    status: WorkFormStatus;
    questions: WorkFormQuestion[];
    facilityIds?: string[];
}

export interface WorkFormUpdateRequest extends Partial<WorkFormCreateRequest> {
    currentVersion?: number;
}

export interface WorkFormResponse extends Taggable {
    id?: string;
    name: string;
    enabled: boolean;
    status: WorkFormStatus;
    questions: WorkFormQuestion[];
    currentVersion: number;
    responseCount?: number;
    facilityIds?: string[];
    versions?: WorkFormVersion[];
    accountId?: string; // populated by mobile services
    whoCanSubmit?: PersonMatcher;
}

export interface WorkFormGetAllResponse extends WorkFormResponse {
    versions: WorkFormVersion[];
    responseCount: number;
}

export interface WorkFormVersion {
    version: string;
    status: WorkFormStatus;
}

export enum WorkFormStatus {
    Draft = 'draft',
    Published = 'published',
    Archived = 'archived',
}

export interface WorkFormQuestion extends WorkFormQuestionInput {
    qId: string;
}

export interface WorkFormQuestionInput {
    qId?: string;
    question: string;
    type: QuestionType;
    details: QuestionDetails;
}

export enum QuestionType {
    Dropdown = 'dropdown',
    Text = 'text',
    Number = 'number',
    PersonPicker = 'person-picker',
    CompanyPicker = 'company-picker',
    ItemPicker = 'item-picker',
    Date = 'date',
    Picture = 'picture',
}

export interface AbstractDetails {
    required: boolean;
    type: QuestionType;
    options?: QuestionOption[];
    selections?: DataFilterSelection;
    resources?: Resource[];
    facilityId?: string;
}

export interface Resource {
    key: any;
    displayText: string;
    isFolder?: boolean;
    isFacility?: boolean;
    isSelectable?: boolean;
    hasEdit?: boolean;
    hasError?: boolean;
    parentId?: string;
    data?: any;
}

/** The discriminated union for question details. Use the `type` property. */
export type QuestionDetails = 
    | DropdownDetails
    | TextDetails
    | NumberDetails
    | PersonPickerDetails
    | CompanyPickerDetails
    | ItemPickerDetails
    | DateDetails
    | PictureDetails
    | SelectionDetails;

export interface DropdownDetails extends AbstractDetails {
    type: QuestionType.Dropdown;
    options: QuestionOption[];
}
export interface QuestionOption {
    display: string;
    value: unknown;
}

export interface TextDetails extends AbstractDetails {
    type: QuestionType.Text;
}

export interface NumberDetails extends AbstractDetails {
    type: QuestionType.Number;
}

export interface SelectionDetails extends AbstractDetails {
    selections: DataFilterSelection;
    resources?: Resource[];
}

export interface DataFilterSelection {
    ids?: string[];
    tags?: string[];
    folders?: string[];
    crafts?: string[];
    facilityId?: string;
}

export interface PersonPickerDetails extends SelectionDetails {
    type: QuestionType.PersonPicker;
}
export interface CompanyPickerDetails extends SelectionDetails {
    type: QuestionType.CompanyPicker;
}
export interface ItemPickerDetails extends SelectionDetails {
    type: QuestionType.ItemPicker;
}

export interface DateDetails extends AbstractDetails {
    type: QuestionType.Date;
}

export interface PictureDetails extends AbstractDetails {
    type: QuestionType.Picture;
}
